<template>
  <div class="header-wrap">
    <div class="logo">
      <img src="../assets/imgs/common/logo.png" alt="" />
    </div>
    <div class="router-list">
      <router-link
        active-class="router-active"
        v-for="(val, ind) in routerList"
        :to="val.path"
        :key="ind"
        tag="a"
        >{{ langType == "zh-cn" ? val.title : val.titleEn }}</router-link
      >
      <img
        class="search"
        src="../assets/imgs/common/search.png"
        alt=""
        @click="searchClick()"
      />
<!--      <div class="language">-->
<!--        <span :class="{ active: langType == 'zh-cn' }" @click="tabLang('zh-cn')"-->
<!--          >中文</span-->
<!--        >-->
<!--        <p>/</p>-->
<!--        <span :class="{ active: langType == 'en-us' }" @click="tabLang('en-us')"-->
<!--          >EN</span-->
<!--        >-->
<!--      </div>-->
    </div>
    <div class="searchbtn" :style="{ display: this.istrue ? 'block' : 'none' }">
      <input
        type="text"
        placeholder="请输入搜索关键词"
        v-model="searchval"
        class="searchinput"
        @keyup.enter="beatClick()"
      />
      <!-- <router-link  to="/SearchDetails"> -->
      <img
        class="search"
        src="../assets/imgs/common/search.png"
        alt=""
        @click="beatClick()"
      />
      <div
        class="searchbtnTips"
        @click="searchtip()"
        :style="{ display: istip ? 'block' : 'none' }"
      >
        请输入关键词
        <div class="x">×</div>
      </div>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
import Router from "@/router/index";
export default {
  name: "headers",
  data() {
    return {
      routers: [],
      routerList: [],
      routerDetailsList: [],
      isChildRouter: false,
      homeRouter: [],
      istrue: false,
      searchval: "",
      istip: false,
      langType: "",
    };
  },
  // watch: {
  //   $route: function (val) {
  //   },
  // },
  created() {
    this.langType = sessionStorage.getItem("langType") || "zh-cn";
    this.routers = Router.options.routes[0].children;
    this.routerList = this.routers.slice(0, 6);
  },
  mounted() {},
  methods: {
    searchClick: function () {
      this.istrue = !this.istrue;
      console.log(this.istrue);
    },
    beatClick: function () {
      console.log(this.searchval);
      if (this.searchval == "") {
        this.istip = true;
      } else {
        this.$router.push({
          name: "SearchDetails",
          query: {
            searchval: this.searchval,
          },
        });
        this.searchval = ""
        this.istrue = false;
      }
    },
    searchtip: function () {
      this.istip = false;
    },
    //切换语言
    tabLang(type) {
      sessionStorage.setItem("langType", type);
      window.location.reload();
    },
  },
};
</script>

<style scoped lang="scss">
.header-wrap {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  color: #fff;
  // background: #ccc;
  position: absolute;
  top: 0;
  left: calc(50% - 600px);
  right: calc(50% - 600px);
  z-index: 100;
  .router-list {
    font-size: 20px;
    display: flex;
    // align-items: center;
    line-height: 50px;
    height: 50px;
    & > a {
      //margin-left: 40px;
      padding: 0 10px;
      color: #fff;
      &::after {
        content: "";
        display: block;
        height: 2px;
        transition: all 200ms ease-in-out 0s;
        cursor: pointer;
        height: 2px;
        background: #fff;
        margin: 0 auto;
        width: 0;
      }
      &.router-active {
        &::after {
          width: 100%;
        }
      }
      &:hover::after {
        width: 100%;
      }
    }

    .search {
      margin-left: 40px;
      vertical-align: middle;
      width: 21px;
      height: 21px;
      cursor: pointer;
      margin-top: 16px;
    }
    .language {
      display: flex;
      margin-left: 14px;
      cursor: pointer;
      & > p {
        margin: 0 10px;
      }
      & > span {
        &::after {
          content: "";
          display: block;
          height: 2px;
          transition: all 200ms ease-in-out 0s;
          cursor: pointer;
          height: 2px;
          background: #fff;
          margin: 0 auto;
          width: 0;
        }
        &.active {
          &::after {
            width: 100%;
          }
        }
        &:hover::after {
          width: 100%;
        }
      }
    }
  }
  .searchbtn {
    width: 218px;
    height: 37px;
    position: absolute;
    top: 105px;
    right: 0;
    border-radius: 15px;
    border: 1px solid #ffffff;
    background: rgba(255, 255, 255, 0.5);
    .searchinput {
      width: 165px;
      height: 20px;
      color: white;
      font-size: 14px;
      background: none;
      margin: 8px 0 0 10px;
      float: left;
    }
    .search {
      vertical-align: middle;
      width: 21px;
      height: 21px;
      cursor: pointer;
      margin-top: 7px;
    }
    input::-webkit-input-placeholder {
      color: #ffffff;
    }
    .searchbtnTips {
      width: 119px;
      height: 27px;
      background: #0072cb;
      border-radius: 1px;
      position: absolute;
      top: 52px;
      left: 88px;
      line-height: 27px;
      text-align: center;
      .x {
        position: absolute;
        top: -9px;
        right: 5px;
        font-size: 12px;
        cursor: pointer;
      }
    }
  }
}
</style>
