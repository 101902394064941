import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        redirect: "/HomePage",
        component: Home,
        children: [
            {
                path: "/HomePage",  //首页
                name: "HomePage",
                title: "首页",
                titleEn:"Home",
                component: () => import( "@/views/HomePage.vue")
            },
            {
                path: "/AboutKangCunDetails",  //集团简介
                name: "AboutKangCunDetails",
                title: "关于康存",
                titleEn:"About Kangcun",
                component: () => import( "@/views/AboutKangCunDetails.vue")
            },
            {
                path: "/BusinessDetails",
                name: "BusinessDetails",
                title: "集团业务",
                titleEn:"Business Layout",
                component: () => import( "@/views/BusinessDetails.vue")
            },
            {
                path: "/Partner",
                name: "Partner",
                title: "合作伙伴",
                titleEn:"Partners",
                component: () => import( "@/views/Partner.vue")
            },
            {
                path: "/NewsDetails",
                name: "NewsDetails",
                title: "资讯中心",
                titleEn:"News center",
                component: () => import( "@/views/NewsDetails.vue")
            },
            {
                path: "/JoinUsDetails",
                name: "JoinUsDetails",
                title: "加入我们",
                titleEn:"Join Us",
                component: () => import( "@/views/JoinUsDetails.vue")
            },
            {
                path: "/NewsItemDetails",
                name: "NewsItemDetails",
                title: "新闻详情",
                titleEn:"home",
                component: () => import( "@/views/NewsItemDetails.vue")
            },
            {
                path: "/JoinUsItemDetails",
                name: "JoinUsItemDetails",
                title: "招聘详情",
                titleEn:"home",
                component: () => import( "@/views/JoinUsItemDetails.vue")
            },
            {
                path: "/SearchDetails",
                name: "SearchDetails",
                title:"搜索",
                titleEn:"home",
                component: () => import( "@/views/Searchdetails.vue")
            }

        ],
    },
    {
        path: "/noPage",
        name: "noPage",
        component: () => import("@/views/noPage"),
        hidden: true
    }
];

const router = new VueRouter({
    routes,
    mode: 'history',
    // mode: 'hash',
});


router.beforeEach((to, from, next) => {
    if (window._hmt) {
        if (to.path) {
            window._hmt.push(['_trackPageview', '/#' + to.fullPath])
        }
    }
    next()

})

export default router;
