<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data(){
    return {}
  },
  created() {
    // var _hmt = _hmt || [];
    // (function() {
    //   var hm = document.createElement("script");
    //   hm.src = "https://hm.baidu.com/hm.js?b2d611a5a12d143c288d4ecf8ffab2e2";
    //   var s = document.getElementsByTagName("script")[0];
    //   s.parentNode.insertBefore(hm, s);
    // })();
  }
}

</script>
<style lang="scss">

</style>
