<template>
  <div class="home">
    <Header></Header>
    <router-view class="router-wrap"></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header"

export default {
  name: 'Home',
  data(){
    return {

    }
  },
  components: {
    Header
  },
  methods:{

  }
}
</script>
<style lang="scss" scoped>
.home{
  width: 100%;
  height: 100%;
  position: relative;

  .router-wrap{
    height: 100%;
    width: 100%;
    min-width: 1200px;
  }
}

</style>
