import Vue from 'vue'
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/css/_diy-element.scss'
import './assets/css/diy.scss'

Vue.prototype.$echarts = echarts;

Vue.use(ElementUI, { size: 'small', zIndex: 5000 });
Vue.config.productionTip = false


Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {

      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent('StorageEvent');
      const storage = {
          setItem: function (k, val) {
              sessionStorage.setItem(k, val);

              // 初始化创建的事件
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

              // 派发对象
              window.dispatchEvent(newStorageEvent)
          }
      }
      return storage.setItem(key, newVal);
  }
}

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?842fdc45b1d1627f35d8928da9cffef5";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
